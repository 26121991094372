import React, { Component } from "react";
import { withFirebase } from "../Firebase";

import * as ROUTES from "../../constants/routes";
import logo from "./logo.png";
import "./landing.css";

import { Container, Box, CssBaseline, Link, Button, classes } from "@material-ui/core";


class LandingBase extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate() {
    if (this.props.firebase.auth.currentUser) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className="signLogo">
          <img src={logo} height={120} width={120} alt="logo" />
        </Box>
        <Box className="velkommenText">
          Velkommen til LELager
        </Box>
      </Container>
    );
  }
}

const Landing = withFirebase(LandingBase);
export default Landing;

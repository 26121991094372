import React, { Component } from "react";
import "./admin.css";

import * as ROLES from "../../constants/roles";

//Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Container, CssBaseline } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

export default class UsersAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      tab: 0
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on("value", snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key
      }));

      this.setState({
        users: usersList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  toggleAdmin(uid, currentState) {
    this.props.firebase.doAdminCheck(uid);

    this.props.firebase.doToggleAdmin(uid, currentState);
  }

  toggleUser(uid, currentState) {
    this.props.firebase.doUserCheck(uid);
    this.props.firebase.doToggleUser(uid, currentState);
  }

  render() {
    const { users, loading } = this.state;
    const { value, index } = this.props;

    return (
      <Container hidden={value !== index}>
        <CssBaseline />
        {loading && <CircularProgress />}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>E-Mail</TableCell>
              <TableCell>Brukernavn</TableCell>
              <TableCell>Bruker-tilgang</TableCell>
              <TableCell>Admin-tilgang</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.uid}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      this.toggleUser(user.uid, user.roles[ROLES.USER])
                    }
                    color={
                      user.roles[ROLES.USER] === ROLES.USER
                        ? "primary"
                        : "secondary"
                    }
                  >
                    {user.roles[ROLES.USER] === ROLES.USER ? "Ja" : "Nei"}
                  </Button>
                </TableCell>

                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      this.toggleAdmin(user.uid, user.roles[ROLES.ADMIN])
                    }
                    color={
                      user.roles[ROLES.ADMIN] === ROLES.ADMIN
                        ? "primary"
                        : "secondary"
                    }
                  >
                    {user.roles[ROLES.ADMIN] === ROLES.ADMIN ? "Ja" : "Nei"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    );
  }
}

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ProductCounter from "./productCounter";
import "./moreInfo.css";

function MoreInfo({ product, open, closeDialog, addFromMoreInfo }) {
  const [openDialog, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(open);
  }, [open]);

  const stockColor = q => {
    let t = parseFloat(q);

    if (t > 5) {
      return "stock";
    } else if (t === 0) {
      return "stock redStock";
    } else if (t <= 5 && t > 0) {
      return "stock yellowStock";
    }
  };
  const handleClose = () => {
    setOpen(false);
    closeDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        className="moreinfo-container"
      >
        <div className="moreinfo-container22">
          <DialogActions>
            <Button onClick={handleClose}>
              <Close />
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="moreinfo-picture">
              <img
                src={product.picture}
                alt="bilde"
                height="auto"
                width="256"
              />
            </div>
            <DialogTitle className="moreinfo-title">
              {product.name}{" "}
            </DialogTitle>
            <DialogContent className="moreinfo-text">
              <div className={stockColor(product.quantity)} />
              <div className="productQuantity">
                {product.quantity} {product.unit}
                {". "}
                på lager
              </div>
              <br />
              {product.location && (
                <div className="moreinfo-location">
                  Lokasjon: {product.location}
                </div>
              )}
              <div className="productDescription">{product.description}</div>
            </DialogContent>
            <div className="btns">
              <ProductCounter product={product} addProduct={addFromMoreInfo} />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default MoreInfo;

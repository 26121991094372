import React, { Component } from "react";
import Select from "react-select";
import * as NUMBER from "../../../constants/numbers";

import { withFirebase } from "../../Firebase";
import { CircularProgress, Button, Box } from "@material-ui/core";

class ChooseProjectBase extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.state = {
      chosenProject: "",
      projects: [],
      newProject: "",
      loading: false,
      options: [],
      oftenUsed: []
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleChangeSelect(event) {
    if (event.__isNew__) {
      //Formatting of projectstring, to capitalize letters
      let pushValue = event.value.replace(/\b\w/g, l => l.toUpperCase());

      this.props.firebase.projects().push({ name: pushValue });
    } else {
      this.chooseProject(event.value);
    }
  }

  sortProjectsByOccurence(all) {
    let arr = all.sort((a, b) => {
      return b.time - a.time;
    });
    let a = [];

    for (var i = 0; i < arr.length; i++) {
      if (!a.includes(arr[i].project)) {
        a.push(arr[i].project);
      } else {
      }
    }
    return a.slice(0, NUMBER.OFTEN_USED_AMOUNT);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase
      .transactions()
      .orderByChild("email")
      .equalTo(this.props.firebase.auth.currentUser.email)
      .on("value", snapshot => {
        const transactionsObject = snapshot.val();

        let transactionsList = [];
        if (transactionsObject) {
          transactionsList = Object.keys(transactionsObject).map(key => ({
            ...transactionsObject[key],
            id: key
          }));
        }

        var result = this.sortProjectsByOccurence(transactionsList);

        this.setState({
          transactions: transactionsList,
          loading: false,
          oftenUsed: result
        });
      });

    this.props.firebase.projects().on("value", snapshot => {
      const projectsObject = snapshot.val();

      const projectsList = Object.keys(projectsObject).map(key => ({
        ...projectsObject[key],
        id: key
      }));
      const optionsList = [];
      for (let item in projectsObject) {
        if (projectsObject[item].active) {
          optionsList.push({
            value: projectsObject[item].name,
            label: projectsObject[item].name
          });
        }
      }

      this.setState({
        projects: projectsList,
        loading: false,
        options: optionsList
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.projects().off();
    this.props.firebase.transactions().off();
  }

  chooseProject(projectName) {
    for (let i in this.state.projects) {
      if (this.state.projects[i].name === projectName) {
        this.props.applyProject(this.state.projects[i]);
        return;
      }
    }
    alert(
      "Finner ikke prosjektet. Velg et annet prosjekt, eller kontakt systemadministrator."
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="topdel">
          <Box p={1} mb={2} color={"#707070"} id="velgpro">
            <Select
              id="dropdownPro"
              options={this.state.options}
              onChange={this.handleChangeSelect}
              value={this.state.newProject}
              placeholder="Søk etter prosjekt..."
            />
          </Box>
        </div>

        <div className="botdel">
          <Box p={1} mt={2}>
            <Box mb={2}>
              <h2 className="ProsjektText" id="ofteProsjektText">
                Ofte valgte prosjekter
              </h2>
            </Box>

            {this.state.loading && <CircularProgress />}
            {this.state.oftenUsed.map(project => (
              <Box id={project} key={project} mb={3}>
                <Button
                  variant="outlined"
                  fullWidth
                  className="top5box"
                  onClick={() => this.chooseProject(project)}
                >
                  {project}
                </Button>
              </Box>
            ))}
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

const ChooseProject = withFirebase(ChooseProjectBase);
export default ChooseProject;

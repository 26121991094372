import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import * as ROLES from "../../constants/roles";
// LELager config
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID
};

// Initialize Firebase
class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
    this.storageRef = app.storage().ref();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then(snapshot => {
            const dbUser = snapshot.val();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  //New product in database
  doAddProduct = product => this.db.ref("product").push(product);

  //Increase the quantity of a specific product,
  doIncreaseProductQuantity = (productId, quantity) => {
    this.db.ref(`product/${productId}`).update({ quantity: quantity });
  };
  updateName = (productId, value) => {
    this.db.ref(`product/${productId}`).update({ name: value });
  };
  updateDescription = (productId, value) => {
    this.db.ref(`product/${productId}`).update({ description: value });
  };
  updatePrice = (productId, value) => {
    this.db.ref(`product/${productId}`).update({ price: value });
  };
  updateBarcode = (productId, value) => {
    this.db.ref(`product/${productId}`).update({ barcode: value });
  };
  updateMin = (productId, value) => {
    this.db.ref(`product/${productId}`).update({ min: value });
  };
  updateMax = (productId, value) => {
    this.db.ref(`product/${productId}`).update({ max: value });
  };

  doSetStatus = (productId, status) => {
    this.db.ref(`product/${productId}`).update({ lagerstatus: status });
  };
  // Decrease quantity of product
  doDecreaseProductQuantity = (productId, quantity) => {
    this.db.ref(`product/${productId}`).update({ quantity: quantity });
    this.doCheckMinValue(productId, quantity);
  };

  doCheckMinValue = (productId, quantity) => {
    const _this = this;
    this.db.ref(`product/${productId}`).once("value", function(snapshot) {
      if (snapshot.val() && parseFloat(snapshot.val().min) > quantity) {
        _this.db.ref("mail").push(snapshot.val());
      }
    });
  };

  doToggleAdmin = (uid, currentState) => {
    if (currentState === ROLES.ADMIN) {
      this.db.ref(`users/${uid}/roles`).update({
        ADMIN: "no"
      });
    } else {
      this.db.ref(`users/${uid}/roles`).update({
        ADMIN: ROLES.ADMIN
      });
    }
  };

  doToggleUser = (uid, currentState) => {
    if (currentState === ROLES.USER) {
      this.db.ref(`users/${uid}/roles`).update({
        USER: "no"
      });
    } else {
      this.db.ref(`users/${uid}/roles`).update({
        USER: ROLES.USER
      });
    }
  };

  doToggleProject = (id, currentState) => {
    if (currentState === false) {
      this.project(id).update({
        active: true
      });
    } else {
      this.project(id).update({
        active: false
      });
    }
  };

  doAdminCheck = uid => {
    this.user(uid).once("value", snapshot => {
      let userObject = snapshot.val();
      if (userObject.roles[ROLES.ADMIN] === ROLES.ADMIN) {
        return true;
      } else {
        return false;
      }
    });
  };

  doUserCheck = uid => {
    this.user(uid).once("value", snapshot => {
      let userObject = snapshot.val();
      if (userObject.roles[ROLES.USER] === ROLES.USER) {
        return true;
      } else {
        return false;
      }
    });
  };

  doTransaction = (
    product,
    project,
    person,
    transactionId,
    email,
    lat,
    long,
    merkordre
  ) => {
    this.db.ref("transaction").push({
      transactionId: transactionId,
      project: project.name,
      projectId: project.id,
      product: product,
      person: person,
      time: Date.now(),
      email: email,
      return: false,
      lat: lat,
      long: long,
      merkordre: merkordre
    });
  };

  doReturnTransaction = (
    product,
    project,
    projectId,
    person,
    transactionId,
    email
  ) => {
    this.db.ref("transaction").push({
      transactionId: "retur-" + transactionId,
      project: project,
      projectId: projectId,
      product: product,
      person: person,
      time: Date.now(),
      email: email
    });
    this.transaction(transactionId).update({ return: true });
  };

  doAddCategory = name => {
    this.categories.push({
      name: name
    });
  };

  doAddLocation = name => {
    this.locations.push({
      name: name
    });
  };

  doPictureUpload = (file, productId) => {
    var uploadTask = this.storageRef.child("images/" + productId).put(file);
    let _this = this;

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      function(error) {
        console.error(error);
        // Handle unsuccessful uploads
      },
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log("File available at", downloadURL);
          _this.db.ref(`product/${productId}`).update({ picture: downloadURL });
        });
      }
    );
  };

  doSignOut = () => this.auth.signOut();

  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref("users");

  product = id => this.db.ref(`product/${id}`);
  products = () => this.db.ref("product");
  productQuantity = id =>
    this.db
      .ref(`product/${id}`)
      .once("value")
      .then(snapshot => {
        snapshot.val();
      });

  project = id => this.db.ref(`project/${id}`);
  projects = () => this.db.ref("project");

  transaction = id => this.db.ref(`transaction/${id}`);
  transactions = () => this.db.ref("transaction");

  category = id => this.db.ref(`categories/${id}`);
  categories = () => this.db.ref("categories");

  location = id => this.db.ref(`locations/${id}`);
  locations = () => this.db.ref("locations");
}

export default Firebase;

import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ChangeField = props => {
  const { open, id, field, current, type } = props;
  // Field: e.g. name, price etc.
  // type: text, number
  let [value, setValue] = React.useState("");

  function handleClose() {
    props.handleClose();
  }

  function SubmitNewValue() {
    props.submitUpdatedValue(id, field, value);
    handleClose();
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Endre {field}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Endre {field} på vare: {current}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="quantity"
            label="Ny verdi"
            value={value}
            type={type}
            fullWidth
            required
            onChange={e => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Lukk
          </Button>
          <Button
            onClick={SubmitNewValue}
            color="primary"
            disabled={value === null || value === ""}
          >
            Lagre
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeField;

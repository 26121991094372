import React, { Component } from "react";
import * as shortId from "shortid";
import { compose } from "recompose";
import { TextField, Link } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import * as ROLES from "../../constants/roles";
import * as NUMBER from "../../constants/numbers";
import * as ROUTES from "../../constants/routes";

import "./cart.css";
import Clear from "@material-ui/icons/Clear";

import {
  CssBaseline,
  Box,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItem,
  Divider,
  IconButton
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

class CartBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: "",
      cart: [],
      lat: "unknown",
      long: "unknown",
      merkordre: "",
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    };
  }

  componentDidMount = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        this.setState({ lat, long });
      },
      error => alert(error.message),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 60000 }
    );
  };

  componentWillMount() {
    if (
      Date.now() - parseFloat(localStorage.getItem("time")) <
      NUMBER.LOCALSTORAGE_SAVETIME
    ) {
      try {
        this.setState({
          cart: JSON.parse(localStorage.getItem("cart")),
          project: JSON.parse(localStorage.getItem("project"))
        });
      } catch (e) {
        this.resetLocalStorage();
      }
    } else {
      this.resetLocalStorage();
    }
  }
  checkoutCart() {
    let tList = this.state.cart; //Duplication and short name for readability

    if (tList.length === 0) {
      // Do nothing if nothing in cart
      return;
    }

    //Short id-string for transaction
    let transactionId = shortId.generate();
    let transactionList = [];
    this.props.firebase

      .user(this.props.firebase.auth.currentUser.uid)
      .once("value", snapshot => {
        let username = snapshot.val().username;
        let email = snapshot.val().email;
        for (let i in tList) {
          transactionList.push({
            product: tList[i].product,
            quantity: tList[i].number
          });

          //Reduce quantity in Firbease
          this.props.firebase.doDecreaseProductQuantity(
            tList[i].product.id,
            tList[i].product.quantity - tList[i].number
          );
        }

        //Save transaction to Firebase
        this.props.firebase.doTransaction(
          transactionList,
          this.state.project,
          username,
          transactionId,
          email,
          this.state.lat,
          this.state.long,
          this.state.merkordre
        );

        //Reset cart
        this.setState({ cart: [], project: "", merkordre: "" });
        this.resetLocalStorage();
      })
      .then(this.props.history.push(ROUTES.RECEIPT));
  }

  removeProductFromCart(product) {
    let tempList = this.state.cart;
    let removalIndex;

    for (let i in tempList) {
      if (tempList[i].product === product.product) {
        removalIndex = i;
      }
    }
    if (removalIndex) {
      tempList.splice(removalIndex, 1);
      this.setState({ cart: tempList });
      this.updateLocalStorage();
    }
  }

  updateLocalStorage() {
    localStorage.setItem("cart", JSON.stringify(this.state.cart));
    localStorage.setItem("project", JSON.stringify(this.state.project));
    localStorage.setItem("time", Date.now());

    //Global event to update mobile navBar
    window.cartCount = this.state.cart.length;
    const event = new Event("updateCart");
    window.dispatchEvent(event);
  }

  resetLocalStorage() {
    localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("project", JSON.stringify(""));
    //Global event to update mobile navBar
    const event = new Event("updateCart");
    window.dispatchEvent(event);
    window.cartCount = 0;
  }

  handleChange(e) {
    this.setState({ merkordre: e.target.value });
  }

  componentWillUnmount() {
    this.updateLocalStorage();
    this.props.firebase.user().off();
  }

  render() {
    return (
      <Container>
        <CssBaseline />
        
        {this.state.cart.length === 0 ? (
          <div>
            <div className="cartIcon">
              <AddShoppingCartIcon className="cartIcon"/>
            </div>
            <h4 className="tomKurvText">Handlevognen er tom</h4>
            <div className="leggtilvare">
              <Link
                onClick={() => {
                  this.props.history.push("/home")
                }}
              >
                <Button>Legg til varer</Button>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="cartTitle">Handlekurv</h2>
            <TextField
              name="price"
              value={this.state.merkordre}
              onChange={this.handleChange.bind(this)}
              type="text"
              variant="outlined"
              label="Merk ordre.."
              fullWidth
              margin="dense"
            />
          </div>
        )}
        <List size="small">
          {this.state.cart.map((product, index) => (
            <ListItem key={product.product.id + index}>
              <ListItemAvatar>
                <Avatar
                  alt={`Bilde av ${product.product.name}`}
                  src={product.product.picture}
                />
              </ListItemAvatar>
              <ListItemText
                primary={product.product.name}
                secondary={product.number + " " + product.product.unit}
              ></ListItemText>

              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => this.removeProductFromCart(product)}
                  variant="outlined"
                  color="secondary"
                >
                  <Clear />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        {this.state.cart.length === 0 ? (
          ""
        ) : (
          <div>
            <Divider />
            <Box mt={1}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.checkoutCart()}
                fullWidth
              >
                Bekreft henting av disse varene
              </Button>
            </Box>
          </div>
        )}
      </Container>
    );
  }
}

const Cart = withFirebase(CartBase);
const condition = authUser =>
  authUser && authUser.roles[ROLES.USER] === ROLES.USER;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Cart);

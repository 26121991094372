import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Help from "@material-ui/icons/HelpOutline";
import RestIcon from "@material-ui/icons/RemoveShoppingCart";
import LagerIcon from "@material-ui/icons/ShoppingCart";
export function StatusMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    setAnchorEl(null);
  };

  const updateStatus = newStatus => {
    props.setNewStatus(props.id, newStatus);
    handleClose();
  };

  const statusIcon = status => {
    switch (status) {
      case "Lagervare":
        return <LagerIcon />;
      case "Rest/bestillingsvare":
        return <RestIcon />;
      case "Vet ikke":
        return <Help />;
      default:
        break;
    }
  };

  return (
    <div>
      <button
        className="btnLagerstatus"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {statusIcon(props.status)}
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => updateStatus("Lagervare")}>
          <LagerIcon />
          Lagervare
        </MenuItem>
        <MenuItem onClick={() => updateStatus("Rest/bestillingsvare")}>
          <RestIcon />
          Rest/bestillingsvare
        </MenuItem>
        <MenuItem onClick={() => updateStatus("Vet ikke")}>
          <Help />
          Vet ikke
        </MenuItem>
      </Menu>
    </div>
  );
}

import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function IncreaseQuantity(props) {
  const { open, id, name, quantity } = props;
  let addQ;

  function handleClose() {
    props.handleClose();
  }

  function submitQuantity() {
    let q = parseFloat(addQ);
    if (q === 0 || isNaN(q) || q === "") {
      handleClose();
    }
    props.submitQuantity(id, parseFloat(quantity) + q);
  }

  function handleChange(event) {
    addQ = event.target.value;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Øk antall</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Skriv inn hvor mange av vare {name} du vil legge til:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="quantity"
            label="Antall"
            value={addQ}
            type="number"
            fullWidth
            required
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Lukk
          </Button>
          <Button
            onClick={submitQuantity}
            color="primary"
            disabled={addQ === null || addQ === ""}
          >
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { Component } from "react";

import { saveAs } from "file-saver";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Container, Box, TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChangeField from "./Dialog/ChangeField";

import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import IncreaseQuantity from "./Dialog/increaseQuantity";
import * as ROLES from "../../constants/roles";
import "./admin.css";
import { StatusMenu } from "./Dialog/StatusMenu";

const { Parser } = require("json2csv");
export default class ProductAdmin extends Component {
  constructor(props) {
    super(props);
    this.submitIncreasedQuantity = this.submitIncreasedQuantity.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.editClose = this.editClose.bind(this);
    this.submitUpdatedValue = this.submitUpdatedValue.bind(this);
    this.setNewStatus = this.setNewStatus.bind(this);

    this.state = {
      loading: false,
      products: [],
      open: false,
      qId: "",
      qName: "",
      qQuantity: "",
      editOpen: false,
      editField: "",
      editId: "",
      editCurrent: "",
      editType: ""
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.products().on("value", snapshot => {
      const productsObject = snapshot.val();

      const productsList = Object.keys(productsObject).map(key => ({
        ...productsObject[key],
        id: key
      }));

      this.setState({
        products: productsList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.products().off();
  }

  deleteThis(id) {
    var r = window.confirm("Vil du slette?");
    if (r === true) {
      this.props.firebase.product(id).remove();
    } else {
      return;
    }
  }

  openIncreaseQuantity(id, name, quantity) {
    this.setState({ open: true, qId: id, qName: name, qQuantity: quantity });
  }

  handleClose() {
    this.setState({ open: false, qId: "", qName: "", qQuantity: "" });
  }

  submitIncreasedQuantity(id, quantity) {
    this.props.firebase.doIncreaseProductQuantity(id, quantity);
    this.handleClose();
  }

  downloadProductsAsCsv() {
    const opts = { header: true, excelStrings: true, delimiter: ";" };
    const parser = new Parser(opts);
    let productList = [];
    for (let i in this.state.products) {
      let p = {
        Navn: this.state.products[i].name,
        Pris: this.state.products[i].price,
        Antall: this.state.products[i].quantity,
        SamletPris:
          this.state.products[i].quantity * this.state.products[i].price
      };
      productList.push(p);
    }
    try {
      const csv = parser.parse(productList);

      var path = "Produktliste.csv";
      var blob = new Blob([csv], {
        type: "text/plain;charset=utf-8"
      });
      saveAs(blob, path);
    } catch (e) {
      console.error(e);
    }
  }

  openEdit(editCurrent, editType, editField, editId) {
    this.setState({ editOpen: true, editType, editField, editId, editCurrent });
  }

  editClose() {
    this.setState({
      editOpen: false,
      editType: "",
      editField: "",
      editId: "",
      editCurrent: ""
    });
  }

  submitUpdatedValue(id, field, value) {
    switch (field) {
      case "Navn":
        this.props.firebase.updateName(id, value);
        break;
      case "NOBB":
        if (typeof parseFloat(value) !== "number") {
          break;
        }
        this.props.firebase.updateBarcode(id, value);
        break;
      case "Pris":
        if (typeof parseFloat(value) !== "number") {
          break;
        }
        this.props.firebase.updatePrice(id, value);
        break;
      case "Beskrivelse":
        this.props.firebase.updateDescription(id, value);
        break;
      case "Min":
        this.props.firebase.updateMin(id, value);
        break;
      case "Max":
        this.props.firebase.updateMax(id, value);
        break;

      default:
        return;
    }
  }

  setNewStatus(id, status) {
    this.props.firebase.doSetStatus(id, status);
  }

  render() {
    const {
      products,
      loading,
      open,
      qId,
      qName,
      qQuantity,
      editOpen,
      editId,
      editField,
      editCurrent,
      editType
    } = this.state;
    const { value, index } = this.props;

    return (
      <Container hidden={value !== index}>
        <Box p={1} className="topBox">
          <Button onClick={() => this.downloadProductsAsCsv()}>
            <GetAppIcon />
            Produtkliste
          </Button>
          <TextField
            className="sokProdukt"
            name="productSearch"
            value={this.state.productSearch}
            onChange={this.onChange}
            margin="dense"
            label="Søk etter produkt"
            variant="outlined"
          />
        </Box>
        {loading && <CircularProgress />}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Beskrivelse</TableCell>
              <TableCell>Kategori</TableCell>
              <TableCell>Nobb</TableCell>
              <TableCell>Pris</TableCell>
              <TableCell>Antall</TableCell>
              <TableCell>Min</TableCell>
              <TableCell>Max</TableCell>
              <TableCell>Lokasjon</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Bilde</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(product => (
              <TableRow key={product.id}>
                <TableCell>
                  <Tooltip title="Klikk for endre navn">
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openEdit(product.name, "text", "Navn", product.id)
                      }
                      margin="dense"
                    >
                      {product.name}
                    </button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Klikk for endre beskrivelse">
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openEdit(
                          product.name,
                          "text",
                          "Beskrivelse",
                          product.id
                        )
                      }
                      margin="dense"
                    >
                      {product.description}
                    </button>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  <Tooltip title="Klikk for endre kategori">
                    <button
                      className="edit-field-button"
                      //dropdown her

                      margin="dense"
                    >
                      {product.category}
                    </button>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  <Tooltip title="Klikk for å endre NOBB">
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openEdit(
                          product.name,
                          "number",
                          "NOBB",
                          product.id
                        )
                      }
                      margin="dense"
                    >
                      {product.barcode ? product.barcode : "Ingen NOBB"}
                    </button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Klikk for å endre pris">
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openEdit(product.name, "text", "Pris", product.id)
                      }
                      margin="dense"
                    >
                      {product.price ? product.price : "Ingen pris"}
                    </button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Klikk for å øke antallet" aria-label="add">
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openIncreaseQuantity(
                          product.id,
                          product.name,
                          product.quantity
                        )
                      }
                      margin="dense"
                    >
                      {product.quantity} {product.unit}
                    </button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {product.lagerstatus === "Lagervare" && (
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openEdit(product.min, "number", "Min", product.id)
                      }
                    >
                      {product.min ? product.min : "Ingen min"}
                    </button>
                  )}
                </TableCell>
                <TableCell>
                  {product.lagerstatus === "Lagervare" && (
                    <button
                      className="edit-field-button"
                      onClick={() =>
                        this.openEdit(product.max, "number", "Max", product.id)
                      }
                    >
                      {product.max ? product.max : "Ingen max"}
                    </button>
                  )}
                </TableCell>
                <TableCell>{product.location}</TableCell>
                <TableCell>
                  <Tooltip
                    title={product.lagerstatus}
                    disableFocusListener
                    placement="left"
                    aria-label="add"
                  >
                    <span className="edit-field-button-icon">
                      <div className="iconStyle">
                        <StatusMenu
                          status={product.lagerstatus}
                          id={product.id}
                          setNewStatus={this.setNewStatus}
                        />
                      </div>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {product.picture !== "" ? (
                    <a href={product.picture}>Bilde</a>
                  ) : (
                    "Ingen bilde"
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    color="secondary"
                    onClick={() => this.deleteThis(product.id)}
                    disabled={
                      this.props.authUser.roles[ROLES.ADMIN] !== ROLES.ADMIN
                    }
                  >
                    Slett
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <IncreaseQuantity
          open={open}
          id={qId}
          name={qName}
          quantity={qQuantity}
          submitQuantity={this.submitIncreasedQuantity}
          handleClose={this.handleClose}
        />
        <ChangeField
          open={editOpen}
          id={editId}
          field={editField}
          current={editCurrent}
          type={editType}
          handleClose={this.editClose}
          submitUpdatedValue={this.submitUpdatedValue}
        />
      </Container>
    );
  }
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  },
  snackbar: {
    top: theme.spacing(2)
  }
}));

export default function SimpleSnackbar(props) {
  const classes = useStyles();
  const { name, quantity, openBar } = props;

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    props.closeBar();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={openBar}
      className={classes.snackbar}
      autoHideDuration={2000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={
        <span id="message-id">
          Lagt til {quantity} av {name}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}

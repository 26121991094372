import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import "./noaccess.css";

class NoAccessBase extends Component {
  render() {
    return (
      <div className="TextNOACC">
        <h3 className="Ingentil">Ingen tilgang</h3>
        <p>Du har foreløpig ingen tilgang</p>
        <p>Vennligst spør systemadministrator om tilgang til siden.</p>
      </div>
    );
  }
}

const NoAccess = withFirebase(NoAccessBase);
const condition = authUser => !!authUser;
export default withAuthorization(condition)(NoAccess);

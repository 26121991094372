import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

import * as ROLES from "../../constants/roles";
import "./create.css";

import CreatableSelect from "react-select/creatable";

//Material
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { MenuItem, Grid, Box } from "@material-ui/core";

const INITIAL_STATE = {
  name: "",
  description: "",
  price: "",
  lagerstatus: "",
  quantity: "",
  barcode: "",
  picture: "",
  category: "",
  file: "",
  unit: "",
  location: ""
};

class CreateBase extends Component {
  constructor(props) {
    super(props);
    this.openDialog = this.openDialog.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
      ...INITIAL_STATE,
      categories: [],
      open: false,
      setOpen: false,
      locations: []
    };
  }

  componentDidMount() {
    this.props.firebase.categories().on("value", snapshot => {
      const categoriesObject = snapshot.val();

      const categoriesList = Object.keys(categoriesObject).map(key => ({
        ...categoriesObject[key],
        id: key
      }));

      this.setState({
        categories: categoriesList
      });
    });

    this.props.firebase.locations().on("value", snapshot => {
      const locationsObject = snapshot.val();

      const selectableLocations = [];
      for (let item in locationsObject) {
        selectableLocations.push({
          value: locationsObject[item].name,
          label: locationsObject[item].name
        });
      }

      this.setState({
        locations: selectableLocations
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.categories().off();
  }

  onSubmit = event => {
    const {
      name,
      description,
      price,
      lagerstatus,
      quantity,
      barcode,
      category,
      picture,
      unit,
      file,
      location
    } = this.state;

    let _this = this;
    let usePrice;
    if (!price || price === "") {
      usePrice = 0;
    } else {
      usePrice = price;
    }

    this.props.firebase
      .doAddProduct({
        name: name,
        description: description,
        price: usePrice,
        lagerstatus: lagerstatus,
        quantity: quantity,
        barcode: barcode,
        category: category,
        picture: "",
        unit: unit,
        addedBy: this.props.firebase.auth.currentUser.email,
        location: location
      })
      .then(evt => {
        if (picture !== null || picture !== "") {
          const productId = evt.key;

          var uploadTask = _this.props.firebase.storageRef
            .child("images/" + productId)
            .put(file);

          // Register three observers:
          // 1. 'state_changed' observer, called any time the state changes
          // 2. Error observer, called on failure
          // 3. Completion observer, called on successful completion
          uploadTask.on(
            "state_changed",
            function(snapshot) {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            function(error) {
              console.error(error);
              // Handle unsuccessful uploads
            },
            function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function(downloadURL) {
                  console.log("File available at", downloadURL);
                  _this.props.firebase.db
                    .ref(`product/${productId}`)
                    .update({ picture: downloadURL });
                });
            }
          );
        }
      })
      .catch(error => console.error(error));
    this.setState({ ...INITIAL_STATE });
    event.preventDefault();
  };

  onChange = event => {
    if (event.target.files) {
      this.setState({ file: event.target.files[0] });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelectChange = event => {
    if (!event) {
      return;
    }
    this.setState({ location: event.value });
  };

  openDialog() {
    this.setState({ setOpen: true, open: true });
  }

  render() {
    const {
      name,
      description,
      price,
      lagerstatus,
      quantity,
      barcode,
      picture,
      category,
      unit,
      open,
      locations
    } = this.state;

    const isInvalid =
      name === "" ||
      description === "" ||
      quantity === "" ||
      unit === "" ||
      category === "" ||
      picture === "";
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <h2 className="leggTilTitle">Legg til ny vare</h2>
        <form onSubmit={this.onSubmit} className="createInput">
          <TextField
            name="name"
            value={name}
            onChange={this.onChange}
            type="text"
            label="Navn på vare"
            variant="outlined"
            fullWidth
            margin="dense"
            required
          />

          <TextField
            name="description"
            value={description}
            onChange={this.onChange}
            type="text"
            label="Beskrivelse"
            variant="outlined"
            fullWidth
            margin="dense"
            required
          />

          <TextField
            name="barcode"
            value={barcode}
            onChange={this.onChange}
            type="text"
            label="Strekkode/NOBB"
            variant="outlined"
            fullWidth
            margin="dense"
          />

          <Grid container>
            <Grid item xs={7} md={7} lg={7} xl={7}>
              <TextField
                name="price"
                value={price}
                onChange={this.onChange}
                type="number"
                variant="outlined"
                label="Pris"
                fullWidth
                margin="dense"
                className="AntallEnhet"
              />
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5}>
              <TextField
                name="lagerstatus"
                value={lagerstatus}
                onChange={this.onChange}
                select
                variant="outlined"
                fullWidth
                label="lagerstatus"
                margin="dense"
                required
                className="lagerstatus"
              >
                <MenuItem value={"Vet ikke"}>Vet ikke</MenuItem>
                <MenuItem value={"Lagervare"}>Lagervare</MenuItem>
                <MenuItem value={"Rest/bestillingsvare"}>Rest/bestillingsvare</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={7} md={7} lg={7} xl={7}>
              <TextField
                name="quantity"
                value={quantity}
                onChange={this.onChange}
                type="number"
                label="Antall på lager"
                variant="outlined"
                margin="dense"
                fullWidth
                required
                className="AntallEnhet"
              />
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5}>
              <TextField
                name="unit"
                value={unit}
                onChange={this.onChange}
                type="text"
                label="Enhet"
                variant="outlined"
                margin="dense"
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <TextField
            name="category"
            value={category}
            onChange={this.onChange}
            select
            variant="outlined"
            fullWidth
            label="Kategori"
            margin="dense"
            required
          >
            {this.state.categories.map(cat => (
              <MenuItem key={cat.id} value={cat.name}>
                {cat.name}
              </MenuItem>
            ))}
          </TextField>

          <CreatableSelect
            classNamePrefix="createSelect"
            isClearable
            placeholder="Lokasjon *"
            //onInputChange={this.handleSelectChange}
            onChange={this.handleSelectChange}
            name="location"
            options={locations}
            formatCreateLabel={value => <span>Ny lokasjon: {value}</span>}
          />
          <TextField
            name="picture"
            value={picture}
            onChange={this.onChange}
            type="file"
            inputProps={{ accept: "image/*", capture: "camera" }}
            variant="outlined"
            fullWidth
            id="fileUploadField"
            margin="dense"
            placeholder="Last opp bilde"
            required
          />
          <Typography variant="caption" display="block" gutterBottom>
            Felter merket med * må fylles ut
          </Typography>
          <Box mt={2}>
            <Button
              type="submit"
              disabled={isInvalid}
              variant="contained"
              color="primary"
              fullWidth
            >
              Legg til Produkt
            </Button>
          </Box>
        </form>
      </Container>
    );
  }
}

const Create = withFirebase(CreateBase);
const condition = authUser =>
  authUser && authUser.roles[ROLES.USER] === ROLES.USER;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Create);

import React, { Component } from "react";
import { compose } from "recompose";
import "./admin.css";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";

import CategoryAdmin from "./category";
import ProductAdmin from "./product";
import ProjectAdmin from "./project";
import UsersAdmin from "./users";
import LocationAdmin from "./locations";

import { AuthUserContext } from "../Session";

//Material UI
import { Container, CssBaseline, Box, Paper } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

class AdminBase extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      loading: false,
      users: [],
      value: 0
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on("value", snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key
      }));

      this.setState({
        users: usersList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  toggleAdmin(uid, currentState) {
    this.props.firebase.doAdminCheck(uid);

    this.props.firebase.doToggleAdmin(uid, currentState);
  }

  toggleUser(uid, currentState) {
    this.props.firebase.doUserCheck(uid);
    this.props.firebase.doToggleUser(uid, currentState);
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  render() {
    return (
      <Container component="main">
        <CssBaseline />
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              <Box className="adminBox">
                <AppBar position="static">
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                  >
                    <Tab label="Prosjekt" value={0} />
                    <Tab label="Produkt" value={1} />
                    <Tab label="Kategori" value={2} />
                    <Tab
                      label="Lokasjon"
                      value={3}
                      disabled={authUser.roles[ROLES.ADMIN] !== ROLES.ADMIN}
                    />
                    <Tab
                      label="Brukere"
                      value={4}
                      disabled={authUser.roles[ROLES.ADMIN] !== ROLES.ADMIN}
                    />
                  </Tabs>
                </AppBar>
                <Paper className="overflow">
                  <ProjectAdmin
                    value={this.state.value}
                    index={0}
                    firebase={this.props.firebase}
                    authUser={authUser}
                  />
                  <ProductAdmin
                    value={this.state.value}
                    index={1}
                    firebase={this.props.firebase}
                    authUser={authUser}
                  />
                  <CategoryAdmin
                    value={this.state.value}
                    index={2}
                    firebase={this.props.firebase}
                    authUser={authUser}
                  />
                  <LocationAdmin
                    value={this.state.value}
                    index={3}
                    firebase={this.props.firebase}
                    authUser={authUser}
                  />
                  <UsersAdmin
                    value={this.state.value}
                    index={4}
                    firebase={this.props.firebase}
                  />
                </Paper>
              </Box>
            ) : (
              <> </>
            )
          }
        </AuthUserContext.Consumer>
      </Container>
    );
  }
}

const AdminPage = withFirebase(AdminBase);
const condition = authUser =>
  authUser && authUser.roles[ROLES.USER] === ROLES.USER;

export default compose(
  withAuthorization(condition),
  withFirebase
)(AdminPage);

export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ADMIN = "/admin";
export const CREATE = "/create";
export const TRANSACTIONS = "/transactions";
export const NO_ACCESS = "/noaccess";
export const FORGOTTEN_PASSWORD = "/forgotten";
export const RETURN = "/return";
export const CART = "/cart";
export const RECEIPT = "/receipt";
export const HELP = "/help";

import React, { Component } from "react";
import { compose } from "recompose";

import Container from "@material-ui/core/Container";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

import * as ROLES from "../../constants/roles";
import * as NUMBER from "../../constants/numbers";

import ChooseProject from "./ChooseProject";
import {
  CircularProgress,
  CssBaseline,
  Divider,
  Box,
  TextField,
  Grid,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import "./home.css";
import SimpleSnackbar from "./snack";
import ProductCounter from "./productCounter";
import MoreInfo from "./moreInfo";

class HomeBase extends Component {
  constructor(props) {
    super(props);
    this.chosenProject = this.chosenProject.bind(this);
    this.closeSnack = this.closeSnack.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.addFromMoreInfo = this.addFromMoreInfo.bind(this);

    this.state = {
      products: [],
      loading: false,
      project: "",
      cart: [],
      productSearch: "",
      open: false,
      snackName: "",
      snackQuantity: "",
      moreInfoProduct: {},
      openDialog: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    if (
      Date.now() - parseFloat(localStorage.getItem("time")) <
      NUMBER.LOCALSTORAGE_SAVETIME
    ) {
      try {
        this.setState({
          cart: JSON.parse(localStorage.getItem("cart")),
          project: JSON.parse(localStorage.getItem("project"))
        });
      } catch (e) {
        this.resetLocalStorage();
      }
    } else {
      this.resetLocalStorage();
    }

    this.props.firebase.products().on("value", snapshot => {
      const productsObject = snapshot.val();

      const productsList = Object.keys(productsObject).map(key => ({
        ...productsObject[key],
        id: key
      }));

      this.setState({
        products: productsList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    if (this.state.cart !== [] && this.state.project !== "") {
      this.updateLocalStorage();
    }

    this.props.firebase.products().off();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addToCart(product, number) {
    let quantityInteger = parseFloat(product.quantity);
    if (number > quantityInteger) {
      console.error("Kan ikke ta ut flere enn som er på lager");
      alert("Kan ikke ta ut flere enn som er på lager");
      return;
    }

    if (number === 0) {
      return;
    }

    let tempList = this.state.cart;
    for (let cartObj in tempList) {
      if (tempList[cartObj].product === product) {
        if (number + tempList[cartObj].number > quantityInteger) {
          console.error("Kan ikke ta ut flere enn som er på lager");
          alert("Kan ikke ta ut flere enn som er på lager");
          return;
        } else {
          tempList[cartObj].number += number;
          this.setState({ cart: tempList });
          this.updateLocalStorage();
          this.openSnack(product.name, number);
          return;
        }
      }
    }
    tempList.push({ product, number });
    this.setState({ cart: tempList });
    this.updateLocalStorage();
    this.openSnack(product.name, number);
  }

  chosenProject(project) {
    this.setState({ project: project });
  }

  resetProject() {
    //Clear localStorage before reset state

    this.setState({ project: "", cart: [] });
    this.resetLocalStorage();
  }

  updateLocalStorage() {
    localStorage.setItem("cart", JSON.stringify(this.state.cart));
    localStorage.setItem("project", JSON.stringify(this.state.project));
    localStorage.setItem("time", Date.now());

    //Global event to update mobile navBar
    window.cartCount = this.state.cart.length;
  }

  resetLocalStorage() {
    localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("project", JSON.stringify(""));

    //Global event to update mobile navBar
    window.cartCount = 0;
  }

  openSnack(name, quantity) {
    this.setState({ open: true, snackName: name, snackQuantity: quantity });
  }
  closeSnack() {
    this.setState({ open: false, snackName: "", snackQuantity: "" });
  }

  stockColor(q) {
    let t = parseFloat(q);

    if (t > 5) {
      return "stock";
    } else if (t === 0) {
      return "stock redStock";
    } else if (t <= 5 && t > 0) {
      return "stock yellowStock";
    }
  }

  openMoreInfo(product) {
    this.setState({ moreInfoProduct: product });

    this.setState({ openDialog: true });
  }

  closeDialog() {
    this.setState({ openDialog: false });
  }

  addFromMoreInfo(product, number) {
    this.addToCart(product, number);
    this.closeDialog();
  }

  render() {
    let {
      open,
      snackName,
      snackQuantity,
      moreInfoProduct,
      openDialog
    } = this.state;
    return (
      <Container className="homeContainer">
        <CssBaseline />
        {this.state.project === "" ? (
          <ChooseProject applyProject={this.chosenProject} />
        ) : (
          <React.Fragment>
            <div className="innipro">
              <SimpleSnackbar
                name={snackName}
                quantity={snackQuantity}
                openBar={open}
                closeBar={this.closeSnack}
              />
              <Box mb={1}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="caption">Valgt prosjekt:</Typography>
                  </Grid>
                  <Grid item>
                    <div className="prosjectName">
                      {this.state.project.name}
                    </div>
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={() => this.resetProject()}
                    >
                      Velg annet prosjekt
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {this.state.loading && <CircularProgress />}
              <Box mb={3}>
                <TextField
                  name="productSearch"
                  value={this.state.productSearch}
                  onChange={this.onChange}
                  fullWidth
                  label="Søk etter produkt"
                  variant="outlined"
                />
              </Box>
              <Divider />
              <ol className="listofitems" id="sorttable">
                {this.state.products.map(product => {
                  if (
                    this.state.productSearch === "" ||
                    product.name
                      .toLowerCase()
                      .includes(this.state.productSearch.toLowerCase())
                  ) {
                    return (
                      <li key={product.id}>
                        <div className="listbox" id={product.id}>
                          <div
                            className="Produktbilde1"
                            onClick={() => this.openMoreInfo(product)}
                          >
                            {product.picture !== "" ? (
                              <img
                                alt="Produktbilde"
                                src={product.picture}
                                height="auto"
                                width="98"
                              />
                            ) : (
                              <img
                                alt="Produktbilde"
                                src="https://via.placeholder.com/256x256.jpg?text=Ingen+bilde"
                                height="98"
                                width="98"
                              />
                            )}
                          </div>
                          <div className="leftRow">
                            <div onClick={() => this.openMoreInfo(product)}>
                              <div className="produktNavn">{product.name}</div>

                              <div className="produktDiscription">
                                {product.description}
                              </div>

                              <div
                                className={this.stockColor(product.quantity)}
                              ></div>
                              <div className="productQuantity">
                                {product.quantity} {product.unit}
                                {". "}
                                på lager
                              </div>
                            </div>
                            <div className="prod">
                              <ProductCounter
                                product={product}
                                addProduct={this.addToCart}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  }
                })}
              </ol>
            </div>
          </React.Fragment>
        )}
        <MoreInfo
          product={moreInfoProduct}
          open={openDialog}
          closeDialog={this.closeDialog}
          addFromMoreInfo={this.addFromMoreInfo}
        />
      </Container>
    );
  }
}

const Home = withFirebase(HomeBase);
const condition = authUser =>
  authUser && authUser.roles[ROLES.USER] === ROLES.USER;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Home);

import React, { Component } from "react";
import { Grid } from "@material-ui/core";

export default class ProductCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 0
    };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  add() {
    this.props.addProduct(this.props.product, this.state.quantity);
    this.setState({ quantity: 0 });
  }

  increaseAmount() {
    let temp = this.state.quantity;
    this.setState({ quantity: temp + 1 });
  }

  decreaseAmount() {
    let temp = this.state.quantity;
    this.setState({ quantity: temp - 1 });
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <button className="decreaseAmount" onClick={() => this.decreaseAmount()} style={{ width: 25 }}>
            -
          </button>
          <input
            className="AmountCounter"
            name="quantity"
            value={this.state.quantity}
            onChange={this.onChange}
            min={1}
            max={999}
            type="number"
            variant="outlined"
            margin="dense"
            style={{ width: 30, textAlign: "center" }}
          />
          <button className="increaseAmount" onClick={() => this.increaseAmount()} style={{ width: 25 }}>
            +
          </button>
        </Grid>
        <Grid item>
          <button className="leggTilBtn" onClick={() => this.add()}>Legg Til</button>
        </Grid>
      </Grid>
    );
  }
}


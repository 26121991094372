import React from "react";

import { withFirebase } from "../Firebase";
import Button from "@material-ui/core/Button";
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

const SignOutButton = ({ firebase }) => (
  <Button type="button" onClick={firebase.doSignOut}>
    <PowerSettingsNew className="icons"/>Logg Ut
  </Button>
);

export default withFirebase(SignOutButton);

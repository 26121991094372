import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import logo from "./logo.png";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ListAlt from "@material-ui/icons/ListAlt";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";

import Hidden from "@material-ui/core/Hidden";
import { Divider, Badge, Box } from "@material-ui/core";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import CompareArrows from "@material-ui/icons/CompareArrows";
import Help from "@material-ui/icons/Help";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
//import Transform from '@material-ui/icons/Transform';

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { AuthUserContext } from "../Session";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./navigation.css";

const useStyles = makeStyles(theme => ({
  bottomBar: {
    top: "auto",
    bottom: 0
  }
}));

const Navigation = () => (
  <React.Fragment>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          <>
            <div className="navigation-wrapper">
              <NavigationAuth authUser={authUser} />
            </div>

            <MobileBottomNavigation />
          </>
        ) : (
          <div className="navigation-wrapper">
            <NavigationNonAuth />
          </div>
        )
      }
    </AuthUserContext.Consumer>
  </React.Fragment>
);

function MobileBottomNavigation() {
  const [value, setValue] = React.useState(0);

  const classes = useStyles();

  return (
    <Hidden mdUp>
      <AppBar position="fixed" className={classes.bottomBar}>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            label="Plukkliste"
            icon={<ListAlt />}
            component={Link}
            to={ROUTES.HOME}
          />

          <BottomNavigationAction
            label="Historikk/Retur"
            icon={<SettingsBackupRestore />}
            component={Link}
            to={ROUTES.RETURN}
          />

          <BottomNavigationAction
            label="Ny Vare"
            icon={<AddIcon />}
            component={Link}
            to={ROUTES.CREATE}
          />
          <BottomNavigationAction
            label="Handlekurv"
            icon={
              <Badge color="primary" badgeContent={window.cartCount}>
                <ShoppingCart />
              </Badge>
            }
            component={Link}
            to={ROUTES.CART}
          />
        </BottomNavigation>
      </AppBar>
    </Hidden>
  );
}

function NavigationAuth({ authUser }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <AppBar color="default" position="fixed">
      <Toolbar>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={4} md={6} lg={5}>
            <Hidden smDown>
              <Link to={ROUTES.HOME}>
                <Button>Plukkliste</Button>
              </Link>
              <Link to={ROUTES.RETURN}>
                <Button>Historikk/Retur</Button>
              </Link>
              <Link to={ROUTES.CREATE}>
                <Button>Ny Vare</Button>
              </Link>
              <Link to={ROUTES.CART}>
                <Button>Kurv</Button>
              </Link>
              
            </Hidden>
            <Hidden smDown>
              {authUser.roles[ROLES.ADMIN] === ROLES.ADMIN && (
                <div className="adminWeb">
                  <Link to={ROUTES.ADMIN}>
                    <Button>Admin</Button>
                  </Link>
                  <Link to={ROUTES.TRANSACTIONS}>
                    <Button>Transaksjoner</Button>
                  </Link>
                </div>
              )}
            </Hidden>
            <Hidden mdUp>
              <Box pt={0.5}>
                <Link to={ROUTES.HOME}><img src={logo} height={40} width={40} alt="logo" /></Link>
              </Box>
            </Hidden>
          </Grid>
          <Grid item xs={6} md={5} lg={6}>
            <Typography>{authUser.username}</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1}>
            <Hidden smDown>
              <SignOutButton />
            </Hidden>
            <Hidden mdUp>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {authUser.roles[ROLES.ADMIN] === ROLES.ADMIN && (
                  <MenuItem>
                    <Link to={ROUTES.TRANSACTIONS}>
                      <Button>
                        <CompareArrows className="icons" />
                        Transaksjoner
                      </Button>
                    </Link>
                  </MenuItem>
                )}
                <MenuItem>
                  <Link to={ROUTES.ADMIN}>
                    <Button>
                      <SupervisorAccount className="icons" />
                      Admin
                    </Button>
                  </Link>
                </MenuItem>

                {authUser.roles[ROLES.ADMIN] === ROLES.ADMIN && <Divider />}
                <MenuItem>
                  <Link to={ROUTES.HELP}>
                    <Button><Help className="icons"/>Hjelp</Button>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <SignOutButton />
                </MenuItem>
              </Menu>
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const NavigationNonAuth = () => (
  <AppBar color="default" position="static">
    <Toolbar>
      <Link to={ROUTES.SIGN_IN}>
        <Button>Logg Inn</Button>
      </Link>
    </Toolbar>
  </AppBar>
);

export { Navigation, MobileBottomNavigation };

import React, { Component } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Container, Box, TextField, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as ROLES from "../../constants/roles";
import Tooltip from "@material-ui/core/Tooltip";

export default class ProjectAdmin extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.newProject = this.newProject.bind(this);

    this.state = { loading: false, projects: [], name: "", id: "" };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.projects().on("value", snapshot => {
      const projectsObject = snapshot.val();

      const projectsList = Object.keys(projectsObject).map(key => ({
        ...projectsObject[key],
        id: key
      }));

      this.setState({
        projects: projectsList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.projects().off();
  }

  deleteThis(id) {
    var r = window.confirm("Vil du slette?");
    if (r === true) {
      this.props.firebase.project(id).remove();
    } else {
      return;
    }
  }

  newProject(e) {
    e.preventDefault();
    this.props.firebase
      .project(this.state.id)
      .set({ name: this.state.name, active: true });
    this.setState({ name: "", id: "" });
    e.preventDefault();
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  toggleProject(id, state) {
    this.props.firebase.doToggleProject(id, state);
  }

  render() {
    const { projects, loading, name, id } = this.state;
    const { value, index } = this.props;
    const isInvalid = name === "" || id === "";
    return (
      <Container hidden={value !== index}>
        <Box p={1} className="topBox">
          <form onSubmit={this.newProject}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <TextField
                  className="inputProsjekt"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  type="text"
                  required
                  label="Navn"
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Tooltip
                title="Hvis du ikke har prosjekt Nr. kontakt Lisa L"
                aria-label="add"
                placement="top"
              >
                <Grid item className="inputProsjekt">
                  <TextField
                    className="inputProsjekt"
                    name="id"
                    value={id}
                    onChange={this.onChange}
                    type="text"
                    required
                    label="ID"
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
              </Tooltip>

              <Tooltip
                title={isInvalid ? "Begge feltene må være utfylt" : ""}
                aria-label="add"
              >
                <Grid item>
                  <Button
                    type="submit"
                    disabled={isInvalid}
                    className="adminAddBtn"
                  >
                    Legg til prosjekt
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </form>
        </Box>

        {loading && <CircularProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Aktiv</TableCell>

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(project => (
              <TableRow key={project.id}>
                <TableCell>{project.name}</TableCell>
                <TableCell>{project.id}</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.toggleProject(project.id, project.active)
                    }
                  >
                    {project.active ? "Aktiv" : "Ikke aktiv"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    color="secondary"
                    onClick={() => this.deleteThis(project.id)}
                    disabled={
                      this.props.authUser.roles[ROLES.ADMIN] !== ROLES.ADMIN
                    }
                  >
                    Slett
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    );
  }
}

import React, { Component } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import { Container, Box, TextField, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as ROLES from "../../constants/roles";

export default class LocationAdmin extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.newLocation = this.newLocation.bind(this);

    this.state = { loading: false, locations: [], name: "" };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.locations().on("value", snapshot => {
      const locationsObject = snapshot.val();

      const locationsList = Object.keys(locationsObject).map(key => ({
        ...locationsObject[key],
        id: key
      }));

      this.setState({
        locations: locationsList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.locations().off();
  }

  deleteThis(id) {
    var r = window.confirm("Vil du slette?");
    if (r === true) {
      this.props.firebase.location(id).remove();
    } else {
      return;
    }
  }

  newLocation(e) {
    e.preventDefault();
    this.props.firebase.locations().push({ name: this.state.name });
    this.setState({ name: "" });
    e.preventDefault();
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { locations, loading, name, id } = this.state;
    const { value, index } = this.props;
    const isInvalid = name === "" || id === "";
    return (
      <Container hidden={value !== index}>
        <Box p={1} className="topBox">
          <form onSubmit={this.newLocation}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <TextField
                  className="inputProsjekt"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  type="text"
                  required
                  label="Sted"
                  variant="outlined"
                  margin="dense"
                />
              </Grid>
              <Tooltip
                title={isInvalid ? "Feltet må være utfylt" : ""}
                aria-label="add"
              >
                <Grid item>
                  <Button
                    type="submit"
                    disabled={isInvalid}
                    className="adminAddBtn"
                  >
                    Legg til lokasjon
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </form>
        </Box>
        {loading && <CircularProgress />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map(location => (
              <TableRow key={location.id}>
                <TableCell>{location.name}</TableCell>
                <TableCell>
                  <Button
                    color="secondary"
                    onClick={() => this.deleteThis(location.id)}
                    disabled={
                      this.props.authUser.roles[ROLES.ADMIN] !== ROLES.ADMIN
                    }
                  >
                    Slett
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    );
  }
}

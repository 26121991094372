import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Loadable from "react-loadable";

import { Navigation } from "../Navigation";
import Landing from "../Landing";
import Home from "../Home";
import Create from "../Create";
import NoAccess from "../NoAccess";
import Admin from "../Admin";

import { CircularProgress, Container, Box } from "@material-ui/core";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import Cart from "../Cart";

const Loading = () => (
  <Container maxWidth="xs">
    <Box p={5}>
      <CircularProgress />
    </Box>
  </Container>
);

const SignInPage = Loadable({
  loader: () => import("../SignIn"),
  loading: Loading,
  delay: 300
});

const PasswordForgetPage = Loadable({
  loader: () => import("../Forgotten"),
  loading: Loading,
  delay: 300
});

const SignUp = Loadable({
  loader: () => import("../SignUp"),
  loading: Loading,
  delay: 300
});

const Transactions = Loadable({
  loader: () => import("../Transactions"),
  loading: Loading,
  delay: 300
});

const ReturnPage = Loadable({
  loader: () => import("../Return"),
  loading: Loading,
  delay: 300
});

const Receipt = Loadable({
  loader: () => import("../Receipt"),
  loading: Loading,
  delay: 300
});

const Help = Loadable({
  loader: () => import("../Help"),
  loading: Loading,
  delay: 300
});

const App = () => (
  <Router>
    <Navigation />
    <Box pb={10}>
      <Route exact path={ROUTES.LANDING} component={Landing} />
      <Route path={ROUTES.SIGN_UP} component={SignUp} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.HOME} component={Home} />
      <Route path={ROUTES.CREATE} component={Create} />
      <Route path={ROUTES.ADMIN} component={Admin} />
      <Route path={ROUTES.TRANSACTIONS} component={Transactions} />
      <Route path={ROUTES.NO_ACCESS} component={NoAccess} />
      <Route path={ROUTES.FORGOTTEN_PASSWORD} component={PasswordForgetPage} />
      <Route path={ROUTES.RETURN} component={ReturnPage} />
      <Route path={ROUTES.CART} component={Cart} />
      <Route path={ROUTES.RECEIPT} component={Receipt} />
      <Route path={ROUTES.HELP} component={Help} />
    </Box>
  </Router>
);

export default withAuthentication(App);
